:root {
	--blue-p: #00a3b3;
	--blue-l: #4dd0e1;
	--blue-el: #b2ebf2;
	--blue-exl: #f5fbfc;
	--blue-03: #03def4;
	--gray-el: #f8f8f8;
	--white: #ffffff;
	--gray-7070: #707070;
	--gray-474A4A: #474a4a;
	--gray-d7: #D7DADD;
	--gray-F4F4F7: #f4f4f7;
	--gray-50: #4b4c4c0a;
	--gray-da: #dadada;
	--gray-ec: #ececec;
	--gray-f3: #f3f3f3;
	--gray-47: #474a4a;
	--gray-24: #242021;
	--gray-e3: #e3e8ee;
	--orange-p: #fc5806;
	--orange-d: #fab390;
	--orange-t: #ff8f00;
	--orange-b: #ff9e2f;
	--error: #ff0000;
	--error-31: #ff3131;
	--warning: #feb62b;
	--success: green;
	--success-new: #63B44D;
	--green-p: #00af3a;
	--info: #00a3b3;
	--gray-d3: #d3d3d3;
	--reject: #ffefef;
	--green-aut: #00af3a;
	--orange-l: #ff6c00;
	--gray-aut: #e3e2e8;
	--gray-l: #f8fafc;
	--blue-pag: #004899;

	--neutral-00: #FFFFFF;
	--neutral-05: #F2F2F2;
	--neutral-10: #E6E6E6;
	--neutral-20: #CDCDCD;
	--neutral-40: #9A9A9A;
	--neutral-60: #686868;
	--neutral-80: #282828;
	--success-light: #E3F2E0;
	--success-medium: #8DC881;
	--success-dark: #1EB100;
	--warning-light: #FEFCF2;
	--warning-medium: #FFE67F;
	--warning-dark: #FFCE00;
	--information-light: #E3F3FF;
	--information-medium: #92C3E7;
	--information-dark: #40A7F2;
	--error-light: #FEDEDE;
	--error-medium: #EC8F8F;
	--error-dark: #F43232 ;
	--bg-00: rgba(248, 248, 248, 0.05);
	--bg-05: rgba(0, 0, 0, 0.05);
	--bg-10: rgba(0, 0, 0, 0.1);
	--bg-20: rgba(0, 0, 0, 0.2);
	--bg-40: rgba(0, 0, 0, 0.4);
	--bg-80: rgba(0, 0, 0, 0.8);
}

:root.orange {
	--color: var(--orange-b)
}

:root, :root.blue {
	--color: var(--info)
}

:root.trader-theme {
	--primary-00: #05A5B7;
	--primary-10: #0098AA;
	--primary-20: #00899A;
	--primary-40: #006472;
	--primary-60: #004F5A;
	--primary-80: #00373E;
	--primary-light-10: #60D7E7;
	--primary-light-20: #80E4F1;
	--primary-light-30: #B8F6FF;
	--primary-light-40: #BFF6FE;
	--primary-light-50: #DFFCFF;
	--primary-light-60: #EEFDFF;
	--secondary-00: #EF6A00;
	--secondary-10: #BF4D00;
	--secondary-20: #803200;
	--secondary-40: #FF8D40;
	--secondary-60: #FFB180;
	--secondary-80: #FFD7BF;
	--secondary-90: #FFF7F0;
	--secondary-light-50: #FFD2B6;
	--secondary-light-70: #FFF0E7;
	--text-light: #BABABA;
	--text-medium: #818181;
	--text-dark: #282828;
	--text-heading: #006472;
	--text-link: #EF6A00;
	--icons-light: #FFFFFF;
	--icons-medium: #BABABA;
	--icons-dark: #05A5B7;
	--icons-dark-2: #EF6A00;

	/* Formulario */
	--input-background: var(--neutral-00);
	--input-border: var(--primary-00);
	--input-background-disabled: var(--neutral-05);
	--input-border-disabled: var(--neutral-20);
	--input-label: var(--primary-00);
	--input-background-focus: var(--primary-light-30);
	--input-border-focus: var(--primary-00);
  --input-text-focus: var(--primary-10);
  --input-background-hover: #F0FEFF;
	--input-border-hover: var(--primary-light-10);
	--input-text-hover: var(--primary-light-10);

	--progress-bar: var(--secondary-00);

	--nav-title: var(--secondary-00);

	--table: var(--primary-00);

	--text: var(--neutral-00);
	--icons: var(--primary-00);

	/* Actions */
	--switch: var(--primary-00);
	--checkbox-color: var(--secondary-00);

	/* Avance de ventas */
	--progressbar-av: var(--secondary-00);
	--progressbar-text-av: var(--neutral-00);
	--progressbar-av-inactive: var(--secondary-60);
	--progressbar-text-av-inactive: var(--neutral-00);
	--text-editor-av: var(--secondary-00);
	--icon-format-av: var(--secondary-00);
	--table-detail-av: var(--secondary-00);

	/* Tooltip */
	--tooltip-question: var(--secondary-00);
	--tooltip-border: var(--secondary-00);
	--tooltip-bg: #FFF7F0;

	--scroll-color: var(--primary-00);
	--chip-report: var(--primary-00);
	--filter-report-border: var(--primary-00);
	--filter-report-selected: var(--secondary-00);
	--text-primary: var(--primary-00);
	--text: var(--neutral-00);

	--tooltip-border: var(--secondary-00);
	--tooltip-bg: #FFF7F0;
	--icon-bg: var(--secondary-00);

	--dropdown-panel-border: var(--primary-20);
}

:root.project-theme {
	--primary-00: #FFCE3A;
	--primary-10: #DFBA28;
	--primary-20: #BFA419;
	--primary-30: #9F8C0D;
	--primary-40: #807304;
	--primary-60: #605900;
	--primary-80: #403D00;
	--primary-light-10: #FFDA48;
	--primary-light-20: #FFE459;
	--primary-light-30: #FFEC6D;
	--primary-light-40: #FFF384;
	--primary-light-50: #FFF89F;
	--primary-light-60: #FFFCBF;
	--primary-light-70: #FFFEDF;
	--secondary-00: #6C3610;
	--secondary-10: #89532D;
	--secondary-20: #A06B47;
	--secondary-40: #CCB087;
	--secondary-50: #D6BA99;
	--secondary-60: #E0CBAD;
	--secondary-70: #EADCC5;
	--text-light: #BABABA;
	--text-medium: #818181;
	--text-dark: #282828;
	--text-heading: #605900;
	--text-link: #BFA419;
	--icons-light: #FFFFFF;
	--icons-medium: #BABABA;
	--icons-dark: #DFBA28;
	--icons-dark-2: #DFBA28;
         


	/* Formulario */
	--input-background: var(--neutral-00);
	--input-border: var(--primary-20);
	--input-background-disabled: var(--neutral-05);
	--input-border-disabled: var(--neutral-20);
	--input-label: var(--primary-20);
	--input-background-focus: var(--primary-light-30);
	--input-border-focus: var(--primary-20);
  --input-text-focus: var(--primary-30);
  --input-background-hover: var(--primary-light-70);
	--input-border-hover: var(--primary-10);
	--input-text-hover: var(--primary-10);

	--progress-bar: var(--primary-00);

	--nav-title: var(--primary-20);

	--table: var(--primary-20);

	--text: var(--neutral-80);
	--icons: var(--primary-20);
	
	/* Actions */
	--switch: var(--primary-20);
	--checkbox-color: var(--primary-20);

	/* Avance de ventas */
	--progressbar-av: var(--primary-00);
	--progressbar-text-av: var(--neutral-80);
	--progressbar-av-inactive: var(--primary-light-60 );
	--progressbar-text-av-inactive: var(--neutral-60);
	--text-editor-av: var(--primary-20);
	--icon-format-av: var(--primary-00);
	--table-detail-av: var(--primary-00);

	/* Tooltip */
	--tooltip-question: var(--primary-00);
	--tooltip-border: var(--primary-20);
	--tooltip-bg: var(--primary-light-70);

	--scroll-color: var(--primary-20);
	--chip-report: var(--primary-20);
	--filter-report-border: var(--primary-20);
	--filter-report-selected: var(--primary-20);
	--text-primary: var(--primary-20);
	--text: var(--neutral-80);

	--tooltip-border: var(--primary-20);
	--tooltip-bg: var(--primary-light-70);
	--icon-bg: var(--primary-00);

	--dropdown-panel-border: var(--primary-20);
}
