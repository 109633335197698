input,
select,
textarea {
  border          : 0px !important;
  border-radius   : 8px !important;
  background-color: #4B4C4C0A !important;
  color           : var(--gray-474A4A) !important;
}

/* input:focus,
select:focus,
textarea:focus {
  outline     : none !important;
  border-color: var(--blue-p) !important;
  box-shadow  : 0 0 6px var(--blue-p) !important;
} */

select {
	appearance: auto !important;
	-webkit-appearance: menulist !important;
}

/**** START SEARCH COMPONENT ****/
.div-search {
  display     : inline-block;
  margin-right: 2%;
  color       : var(--icons-dark);
  text-align  : left;
  position    : relative;
}

.input-search {
  height: 40px !important;
  padding-left: 28px;
}

.icono-busqueda {
  padding: 10px;
  width: 35px;
  height: 40px;
  min-width: 35px;
  position: absolute;
}

/**** END SEARCH COMPONENT ****/


/**** INPUTS TRANSPARENTES CON BLANCO ********/
.input-white {
  background-color: transparent !important;
  color           : #FFFFFF !important;
  border          : 1px #FFFFFF solid !important;
  border-radius   : 10px !important;
}

.input-white:focus {
  outline     : none !important;
  border-color: var(--white) !important;
  box-shadow  : 0 0 6px var(--white) !important;
}

.input-white:focus {
  color: #FFFFFF !important;
}

.input-white::placeholder {
  color  : #FFFFFF !important;
  opacity: 1;
  /* Firefox */
}

.input-white:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #FFFFFF !important;
}

.i-login::-ms-input-placeholder {
  /* Microsoft Edge */
  input-white: #FFFFFF !important;
}

/**** INPUTS TRANSPARENTES CON BLANCO ********/

.input-datepicker {
  padding: 5px !important;
  height : 25px !important;
}

/****  CHECKBOX  ****/
.mat-checkbox-inner-container {
  height: 23px !important;
  width: 23px !important;
}

.mat-checkbox-frame {
  border-radius: 6px!important;
  border: transparent !important;
  background-color: var(--gray-ec) !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #4dd0e1;
  border-radius: 8px;
  width: 23px;
  height: 23px;
  background-color: var(--blue-p);
}

.mat-checkbox-checkmark {
  top: 25% !important;
  left: 25% !important;
  right: 0 !important;
  bottom: 0 !important;
  position: absolute !important;
  width: 50% !important;
}

/* SWITCH */

.switch-custom:not(.orange) .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: var(--switch) !important;
}

.switch-custom:not(.orange) .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
	background: var(--switch) !important;
}

.switch-custom.orange .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: var(--orange-t) !important;
}

.switch-custom.orange .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
	background: var(--orange-t) !important;
}

.switch-custom .p-inputswitch {
	/* display: inherit !important; */
	width: 1.9rem !important;
	height: 1.1rem !important;
}

.switch-custom .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
	transform: translate(.89rem) !important;
}

.switch-custom .p-inputswitch.p-focus .p-inputswitch-slider {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.switch-custom .p-inputswitch .p-inputswitch-slider:before {
	width: 0.75rem !important;
	height: 0.75rem !important;
	left: 0.15rem !important;
	margin-top: -0.38rem !important;
}

/* SWITCH OFF/ON  */

.switch-on-off .p-inputswitch {
  width: 4rem;
  height: 2rem;
}

.switch-on-off .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #00CD21 !important;
}

.switch-on-off .p-inputswitch .p-inputswitch-slider:before {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.725rem;
}

.switch-on-off .p-inputswitch .p-inputswitch-slider::after {
  content: 'Off';
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  color: #FFFFFF;
  top: 50%;
  right: 0.6rem;
  margin-top: -0.625rem;
}

.switch-on-off .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::after {
  content: 'On';
  left: 0.45rem;
  right: inherit;
}

.switch-on-off .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.95rem);
}

/* Formularios */

.form-control-custom, 
.custom-filter-panel .p-dropdown-header .p-dropdown-filter {
  height: 40px;
  border: 1px solid var(--input-border) !important;
  background-color: var(--input-background) !important;
}

.form-control-custom.ng-touched.ng-invalid {
  border-color: #E30613 !important;
  background-color: #FFF7F7 !important;
  color: rgba(227, 6, 19, 0.5) !important;
}

.form-control-custom:disabled {
  border-color: var(--neutral-20) !important;
  background-color: #F2F2F2 !important;
  color: var(--text-light) !important;
}

.form-control-custom:not(:disabled):hover,
.custom-filter-panel .p-dropdown-header .p-dropdown-filter:hover {
  border-color: var(--input-border-hover) !important;
  background-color: var(--input-background-hover) !important;
}

.form-control-custom:not(:disabled):hover::placeholder {
  color: var(--input-text-hover) !important;
}

.form-control-custom:not(:disabled):focus,
.custom-filter-panel .p-dropdown-header .p-dropdown-filter:focus {
  border-color: var(--input-border-focus) !important;
  background-color: var(--input-background-focus) !important;
  box-shadow: none;
}

.form-control-custom:not(:disabled):focus::placeholder {
  color: var(--input-text-focus) !important;
}

.label-custom {
  color: var(--input-label);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.dropdown-custom .p-dropdown:not(.p-disabled) {
  border: 1px solid var(--input-border);
	border-radius: 8px;
}

.dropdown-custom.ng-dirty.ng-invalid.ng-untouched > .p-dropdown {
  border-color: var(--input-border);
}

.dropdown-custom .p-dropdown:not(.p-disabled):hover {
  border-color: var(--input-border-hover) !important;
  background-color: var(--input-background-hover) !important;
}

.dropdown-custom .p-dropdown:not(.p-disabled):hover .p-placeholder {
  color: var(--input-text-hover) !important;
}

.dropdown-custom .p-dropdown:not(.p-disabled):hover .p-dropdown-trigger {
  color: var(--input-border-hover) !important;
}

.dropdown-custom .p-dropdown:not(.p-disabled):focus, 
.dropdown-custom .p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--input-border-focus) !important;
  background-color: var(--input-background-focus) !important;
  box-shadow: none !important;
}

.dropdown-custom .p-dropdown:not(.p-disabled):focus .p-placeholder,
.dropdown-custom .p-dropdown:not(.p-disabled).p-focus .p-placeholder {
  color: var(--input-text-focus) !important;
}

.dropdown-custom .p-dropdown:not(.p-disabled):focus .p-dropdown-trigger,
.dropdown-custom .p-dropdown:not(.p-disabled).p-focus .p-dropdown-trigger {
  color: var(--input-border-focus) !important;
}

.custom-filter-panel {
  border: none;
  box-shadow: 0px 0px 4px 0px var(--dropdown-panel-border);
}

.custom-filter-panel > .p-dropdown-items-wrapper > .p-dropdown-items > .p-element:not(:last-of-type) .p-dropdown-item {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: color-mix(in srgb, var(--dropdown-panel-border) 30%, transparent);
}

.custom-filter-panel .p-dropdown-header .p-dropdown-filter-icon  {
  color: var(--icons);
}