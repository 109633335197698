.validations {
    color    : var(--error);
    font-size: 12px;
}

.subt-size {
    font-size: 22px;
    color    : var(--blue-p);
}

.t-size {
    font-size  : 25px !important;
    color      : #464A4A;
    padding-top: 10px;
    font-weight: 500 !important;
}

.f-error {
    color: var(--error);
}

.f-gray{
    color: var(--gray-474A4A);
}

.f-gray-da {
    color: var(--gray-da);
}

.f-blue-p {
    color: var(--blue-p) !important;
}

.question-desc {
    font-size: 14px;
    color    : var(--gray-474A4A);
}

.f-orange-p {
    color: var(--orange-p);
}

.f-orange-l {
	color: var(--orange-l)
}

.f-green {
	color: var(--green-p) !important;
}

.sections {
    font-size  : 18px;
    color      : var(--blue-p);
    font-weight: 500;
}

.f-23 {
	font-size: 23px;
}

.f-orange-b {
	color: var(--orange-b) !important;
}

.f-poppins {
  font-family: 'Poppins', 'Arial' !important;
}

.f-roboto {
  font-family: 'Roboto', 'Arial' !important;
}