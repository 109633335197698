@use '@angular/material' as mat;
@include mat.core();

$project-palette: (
 50: #FFCE3A,
 100: #FFCE3A,
 200: #FFCE3A,
 300: #FFCE3A,
 400: #BFA419,
 500: #FFFCBF,
 600: #FFCE3A,
 700: #FFCE3A,
 800: #FFCE3A,
 900: #FFCE3A,
 // ... continues to 900
 contrast: (
   50: #BABABA,
   100: #BABABA,
   200: #BABABA,
   300: #000,
   400: #BABABA,
   500: #BABABA,
   600: #BABABA,
   700: #BABABA,
   800: #BABABA,
   900: #BABABA,
   // ... continues to 900
 )
); 

$trader-palette: (
 50: #EF6A00,
 100: #EF6A00,
 200: #05A5B7,
 300: #EF6A00,
 400: #EF6A00,
 500: #EF6A00,
 600: #EF6A00,
 700: #EF6A00,
 800: #EF6A00,
 900: #EF6A00,
 // ... continues to 900
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   // ... continues to 900
 )
);
$custom-primary: mat.define-palette($trader-palette, 300);
$custom-accent: mat.define-palette($trader-palette, 300);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
    ),
  )
);

.trader-theme {
  @include mat.button-theme($custom-theme);
  @include mat.checkbox-theme($custom-theme);
  @include mat.radio-theme($custom-theme);
  @include mat.progress-spinner-theme($custom-theme);
  @include mat.progress-bar-theme($custom-theme);
  @include mat.slide-toggle-theme($custom-theme);
  .mat-button {
    color: var(--primary-00);
  }
  .mat-flat-button {
    background-color: var(--primary-00);
    color: #FFF;
  }
  .mat-flat-button.mat-button-disabled {
    background-color: #FFD2B6 !important;
    color: #FFF !important;
  }
  .mat-stroked-button {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    color: var(--primary-00);
  }
  .mat-stroked-button.mat-button-disabled {
    background-color: #FFF !important;
    color: #BABABA !important;
  }
  .mat-progress-bar-buffer {
    background-color: #CECECE;
  }
}

$custom-primary-project: mat.define-palette($project-palette, 300);
$custom-accent-project: mat.define-palette($project-palette, 400);

$custom-theme-project: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary-project,
      accent: $custom-accent-project,
    ),
  )
);

.project-theme {
  @include mat.button-theme($custom-theme-project);
  @include mat.checkbox-theme($custom-theme-project);
  @include mat.radio-theme($custom-theme-project);
  @include mat.progress-spinner-theme($custom-theme-project);
  @include mat.progress-bar-theme($custom-theme-project);
  @include mat.slide-toggle-theme($custom-theme-project);
  .mat-button {
    color: var(--primary-00);
  }
  .mat-flat-button {
    background-color: var(--primary-00);
    color: #000;
  }
  .mat-flat-button.mat-button-disabled {
    background-color: #FFFCBF !important;
    color: #BABABA !important;
  }
  .mat-stroked-button {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    color: var(--primary-20);
  }
  .mat-stroked-button.mat-button-disabled {
    background-color: #FFF !important;
    color: #BABABA !important;
  }
  .mat-progress-bar-buffer {
    background-color: #CECECE;
  }
}