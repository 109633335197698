.tooltip-app {
  border-width: 2px;
  border-style: solid;
  border-radius: 7px;
  background-color: var(--tooltip-bg);
  /* padding: 10px 16px; */
  max-width: 250px;
  min-height: 20px;
  position: absolute;
  z-index: 1056;
  border-color: var(--tooltip-border);
}

.tooltip-app-header {
  background-color: var(--primary-00);
  color: var(--text);
  padding: 10px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.tooltip-app p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  padding: 10px;
}

.tooltip-app:not(.cut).top-left {
  mask-image: radial-gradient(circle at bottom left, transparent 12px, black 11px);
}

.tooltip-app:not(.cut).bottom-left {
  mask-image: radial-gradient(circle at top left, transparent 12px, black 11px);
}

.tooltip-app.bottom-right {
  mask-image: radial-gradient(circle at top right, transparent 12px, black 11px);
}

/* TOOLTIP V2 */

.tooltip-app-v2 {
  border-width: 2px;
  border-style: solid;
  border-radius: 7px;
  border-color: var(--tooltip-border);
  background-color: var(--tooltip-bg);
  padding: 5px 10px;
  max-width: 200px;
  min-height: 20px;
  position: absolute;
  z-index: 1056;
  border-color: var(--tooltip-border);
}

.tooltip-app-v2 > p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
